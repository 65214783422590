import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { UserContext } from '../AuthProvider';
import { Mail } from 'lucide-react';

// Modified SubscriptionModal component to support "Auf Anfrage" pricing
const SubscriptionModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleContactRequest = () => {
    // Navigate to contact page instead of direct subscription
    navigate('/contact');
  };

  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold mb-4 text-black">Abonnement-Optionen</h2>
      <p className="mb-4 text-black">Unsere Pläne sind derzeit nur auf Anfrage verfügbar.</p>
      <button
        onClick={handleContactRequest}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Kontaktieren Sie uns für ein Angebot
      </button>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
        {/* Professional Plan */}
        <div className="bg-white bg-opacity-50 rounded-lg p-4 shadow-md">
          <h3 className="text-lg font-bold mb-2">Professional</h3>
          <p className="text-xl font-bold mb-4">Auf Anfrage</p>
          <ul className="text-left mb-4">
            <li className="mb-1">✓ 1 Thema Ihrer Wahl</li>
            <li className="mb-1">✓ 500 Textgenerierungen/Monat</li>
            <li className="mb-1">✓ 20 aggregierte Artikel/Tag</li>
          </ul>
          <button
            onClick={handleContactRequest}
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Anfrage senden
          </button>
        </div>
          
        {/* Company Plan */}
        <div className="bg-white bg-opacity-50 rounded-lg p-4 shadow-md">
          <h3 className="text-lg font-bold mb-2">Company</h3>
          <p className="text-xl font-bold mb-4">Auf Anfrage</p>
          <ul className="text-left mb-4">
            <li className="mb-1">✓ Alle Professional Features</li>
            <li className="mb-1">✓ 1.500 Textgenerierungen/Monat</li>
            <li className="mb-1">✓ 50 aggregierte Artikel/Tag</li>
          </ul>
          <button
            onClick={handleContactRequest}
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Anfrage senden
          </button>
        </div>
          
        {/* Enterprise Plan */}
        <div className="bg-white bg-opacity-50 rounded-lg p-4 shadow-md">
          <h3 className="text-lg font-bold mb-2">Enterprise</h3>
          <p className="text-xl font-bold mb-4">Auf Anfrage</p>
          <ul className="text-left mb-4">
            <li className="mb-1">✓ Maßgeschneiderte Lösungen</li>
            <li className="mb-1">✓ Gemeinsame Entwicklung</li>
            <li className="mb-1">✓ API-Zugang</li>
          </ul>
          <button
            onClick={handleContactRequest}
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Kontaktieren Sie uns
          </button>
        </div>
      </div>
    </div>
  );
};

const UserProfileComponent = () => {
  const [topics, setTopics] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [hasServiceAccess, setHasServiceAccess] = useState(false);
  const [isFacebookConnected, setIsFacebookConnected] = useState(false);
  const [isTwitterConnected, setIsTwitterConnected] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [paymentStatus, setPaymentStatus] = useState('');
  const [trialStatus, setTrialStatus] = useState(null);
  const [isActivatingTrial, setIsActivatingTrial] = useState(false);
  

  const { user, setUser, setServiceAccessUpdated } = useContext(UserContext);

  if (user) console.log(user);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTopics();
    fetchContentTypes();
    checkTwitterConnection();
    checkFacebookConnection();
    checkServiceAccess();
    checkTrialStatus();

  }, []);

  useEffect(() => {
    if (user) {
      updateUserInfo();
      updatePaymentStatus();
    }
  }, [user, hasServiceAccess]);

  const checkTrialStatus = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/trial-status`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setTrialStatus(data);
    } catch (error) {
      console.error('Error checking trial status:', error);
    }
  };

  const activateTrial = async () => {
    setIsActivatingTrial(true);
    try {
      const response = await fetch(`${config.apiUrl}/activate-trial`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
      
      const data = await response.json();
      setMessage('Testphase erfolgreich aktiviert!');
      checkServiceAccess();
      checkTrialStatus();
    } catch (error) {
      setMessage(error.message);
    } finally {
      setIsActivatingTrial(false);
    }
  };

  const updateUserInfo = () => {
    setUserInfo({
      userName: user.user_name,
      email: maskEmail(user.email),
      memberSince: user.created_at ? new Date(user.created_at).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '.') : null,
    });
  };

  const maskEmail = (email) => {
    if (!email) return '';
    const [localPart, domain] = email.split('@');
    return `${localPart[0]}${'*'.repeat(localPart.length - 1)}@${domain}`;
  };

  const updatePaymentStatus = () => {
    setPaymentStatus(getPaymentStatus(user.paid_until));
  };

  const fetchTopics = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/topic/get-all-active`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch topics');
      const data = await response.json();
      setTopics(data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchContentTypes = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/content/get-all-active`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch content types');
      const data = await response.json();
      setContentTypes(data);
    } catch (error) {
      console.error('Error fetching content types:', error);
    }
  };

  const activateCoupon = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/user/activate-coupon`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: couponCode }),
      });
      if (!response.ok) throw new Error(await response.text());
      setMessage('Coupon erfolgreich aktiviert');
      setCouponCode('');
      checkServiceAccess(); // Refresh the service access status
    } catch (error) {
      console.error('Error activating coupon:', error);
      setMessage(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        window.location.href = '/login';
      } else {
        console.error('Failed to logout');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const checkServiceAccess = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/check-service-access`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to check service access');
      const data = await response.json();
      setHasServiceAccess(data.has_access);
      setServiceAccessUpdated(data.has_access);
    } catch (error) {
      console.error('Error checking service access:', error);
    }
  };

  const checkTwitterConnection = async () => {
  
    try {
      const response = await fetch(`${config.apiUrl}/user/twitter/connected`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setIsTwitterConnected(data.connected);
    } catch (error) {
      console.error('Error checking Twitter connection:', error);
    }
  };

  const checkFacebookConnection = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/facebook/connected`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setIsFacebookConnected(data.connected);
    } catch (error) {
      console.error('Error checking Facebook connection:', error);
    }
  };

  const connectTwitter = async () => {
    window.location.href = `${config.apiUrl}/twitter/auth`;
  };

  const disconnectTwitter = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/twitter/disconnect`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setIsTwitterConnected(data.connected);
    } catch (error) {
      console.error('Error disconnecting Twitter:', error);
    }
  };

  const connectFacebook = async () => {
    window.location.href = `${config.apiUrl}/facebook/auth`;
  };

  const disconnectFacebook = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/user/facebook/disconnect`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setIsFacebookConnected(data.connected);
    } catch (error) {
      console.error('Error disconnecting Facebook:', error);
    }
  };

  const renderUserInfo = (label, value) => (
    <p className="text-black mb-2">
      <span className="font-semibold">{label}:</span>{' '}
      {value || <span className="animate-pulse bg-gray-300 rounded h-4 w-24 inline-block"></span>}
    </p>
  );

  const getPaymentStatus = (paidUntil) => {
    if (!paidUntil && hasServiceAccess) return <span className="">Testaccount</span>;
    
    const now = new Date();
    const paidUntilDate = new Date(paidUntil);
    
    if (paidUntilDate <= now) {
      if (hasServiceAccess) {
        return <span className="">Testaccount</span>;
      }
      return <span className="text-red-600 font-semibold">inaktiv</span>;
    } else {
      const daysLeft = Math.ceil((paidUntilDate - now) / (1000 * 60 * 60 * 24));
      return (
        <>
          <span className="text-green-600 font-semibold">aktiv -</span>
          <span className="text-black"> (noch {daysLeft} Tage)</span>
        </>
      );
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-end mb-4">
        <button
          onClick={logout}
          className="bg-red-500 bg-opacity-80 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Abmelden
        </button>
      </div>
      
      {/* Testphase Info Section */}
      {!hasServiceAccess && trialStatus?.can_start_trial && (
      <div className="bg-white bg-opacity-20 rounded-lg p-6 mb-8 static">
          <div className="text-center mb-6">
            <h1 className="text-2xl font-bold text-black mb-2">
              Sie können die Funktionen des IntelliScout kostenlos testen.
            </h1>
            <p className="text-lg text-black mb-4">
              Das voreingestellte Thema ist 'Artificial Intelligence'.
            </p>
              <button 
                onClick={activateTrial}
                disabled={isActivatingTrial}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg text-lg"
              >
                {isActivatingTrial ? 'Wird aktiviert...' : 'Kostenlose Testphase starten'}
              </button>
            
          </div>
        </div>
      )}

      {/* Topic Instructions Section
      <div className="bg-white bg-opacity-20 rounded-lg p-6 mb-8 static">
        <SubscriptionModal />
      </div> */}

      {/* User Information Section */}
      <div className="bg-white bg-opacity-20 rounded-lg p-6 mb-8 static">
        <h2 className="text-2xl font-bold mb-4 text-black">Benutzerinformationen</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            {renderUserInfo("Benutzername", userInfo.userName)}
            {renderUserInfo("E-Mail", userInfo.email)}
          </div>
          <div>
            {renderUserInfo("Mitglied seit", userInfo.memberSince)}
          </div>
        </div>
      </div>

      {/* Topics Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4 text-black">Ihre Themen</h2>
        <div className="bg-white bg-opacity-20 rounded-lg p-6 mb-8 static">
          <ul className="list-disc list-inside">
            {topics.map((topic) => (
              <li key={topic.id} className="text-black">{topic.name}</li>
            ))}
          </ul>
        </div>
      </div>

      {/* Content Types and Style Personalization Section */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-black">Verfügbare Inhaltstypen</h2>
          <button
            onClick={() => navigate('/style-examples')}
            className="bg-purple-500 bg-opacity-80 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          >
            Stilanpassung
          </button>
        </div>
        <div className="bg-white bg-opacity-20 rounded-lg p-6 mb-8 static">
        <ul className="list-disc list-inside">
            {contentTypes.map((contentType) => (
              <li key={contentType.id} className="text-black">{contentType.name}</li>
            ))}
          </ul>
        </div>
      </div>

      {/* Service Status and Trial Section */}
        {hasServiceAccess && (
            <div className="bg-white bg-opacity-20 rounded-lg p-6 mb-8 static">
            <h2 className="text-2xl font-bold mb-4 text-black">Service-Status</h2>
        
            <div className="text-green-800 font-semibold">
              Sie haben aktiven Servicezugriff
              {trialStatus?.trial_status === 'active' && (
                <div className="text-sm mt-2">
                  Ihre Testphase endet in {trialStatus.days_remaining} Tagen
                </div>
              )}
            </div>
          </div>

        )}
    </div>
  );
};

export default UserProfileComponent;