import React, { useState, useContext, useEffect, useRef } from 'react';
import { UserContext } from '../../AuthProvider';
import ContentTypeSelector from '../../cockpit/ContentTypeSelector';
import '../NewsArticleStyles.css';
import '../../App.css';
import config from '../../config';
import ArticleCharts from './ArticleCharts';
import ArticleActions from './ArticleActions';
import ArticleTitle from './ArticleTitle';
import ArticleImage from './ArticleImage';
import DeleteButton from './DeleteButton';

const ArticleCard = ({ article, onDelete, similarityScore }) => {
  console.log("article: ", article)
  const cardBackContentRef = useRef(null);
  const cardFrontContentRef = useRef(null);

  const [showPopup, setShowPopup] = useState(false);
  const [isCardFlipped, setIsCardFlipped] = useState(false);

  const { user } = useContext(UserContext);
  const isAdmin = user?.roles?.includes('admin');

  const adjustFlipContainerHeight = () => {
    const containers = document.querySelectorAll('.flip-container');
    
    containers.forEach(container => {
      const front = container.querySelector('.front');
      if (front) {
        const height = front.offsetHeight;
        container.style.height = `${height}px`;
      }
    });
  };

  const toggleCardFlip = () => {
    setIsCardFlipped(!isCardFlipped);
  };

  const handleDeleteArticle = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/delete-article/${article.id}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (response.ok) {
        onDelete(article.id);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const adjustFontSizeToFit = (ref) => {
    if (ref.current) {
      let container = ref.current;
      let fontSize = 1.0;
      let lineHeight = 1.4;

      const maxWidth = 701;
      let maxContainerHeight = window.innerWidth > maxWidth ? 140 : 180;
  
      container.style.fontSize = `${fontSize}em`;
      container.style.lineHeight = `${lineHeight * fontSize}em`;
  
      while (container.offsetHeight > maxContainerHeight && fontSize > 0.1) {
        fontSize-=0.05;
        container.style.fontSize = `${fontSize}em`;
        container.style.lineHeight = `${lineHeight * fontSize}em`;
      }
    }
  };

  useEffect(() => {
    adjustFontSizeToFit(cardBackContentRef);
    adjustFontSizeToFit(cardFrontContentRef);
    adjustFlipContainerHeight();
  }, [isCardFlipped, article.reasoning]);
  
  return (
    <div className="relative overflow-hidden">
      <div className={`flip-container ${isCardFlipped ? 'flipped' : ''}`} style={{ position: 'relative' }}>
        {article.trending_counter >= 1 && (
          <div className="absolute top-3 right-0 bg-red-500 text-white px-8 py-1 transform rotate-45 translate-x-9 shadow-md z-10 text-xs font-bold">
            Trending
          </div>
        )}
        <div className="flipper">
          <div className="front">
            <div className="mainContainer">
              <div className="row card-back-content" ref={cardFrontContentRef}>
                <div className="centered text-center">
                  <ArticleTitle title={article.title}/>
                  <p>{article.summary}</p>
                </div>
              </div>
              <div className="row">
                <ArticleImage article={article} />
                <ArticleCharts article={article} />
              </div>
              <div className="relative">
                <ArticleActions article={article} onFlip={toggleCardFlip} onShowCockpit={() => setShowPopup(true)} leftBtnTxt="Evaluierung" />
                {similarityScore !== undefined && (
                  <div className="absolute bottom-0 right-0 bg-blue-100 text-blue-800 px-2 py-1 rounded-tl text-sm font-medium">
                    {(similarityScore * 100).toFixed(1)}%
                  </div>
                )}
              </div>
            </div>
            {isAdmin && <DeleteButton onDelete={handleDeleteArticle} />}
          </div>
          <div className="back">
            <div className="mainContainer">
              <div className="row card-back-content" ref={cardBackContentRef}>
                {article.reasoning}
              </div>
              <div className="row">
                <ArticleImage article={article} />
                <ArticleCharts article={article} />
              </div>
              <div className="relative">
                <ArticleActions article={article} onFlip={toggleCardFlip} onShowCockpit={() => setShowPopup(true)} leftBtnTxt="Zusammenfassung" />
                {similarityScore !== undefined && (
                  <div className="absolute bottom-0 right-0 bg-blue-100 text-blue-800 px-2 py-1 rounded-tl text-sm font-medium">
                    {(similarityScore * 100).toFixed(1)}%
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {showPopup && (
        <ContentTypeSelector onClose={() => setShowPopup(false)} article={article} />
      )}
    </div>
  );
};

export default ArticleCard;